import gql from 'graphql-tag';

export const AnalyticsContentQuery = gql`
	query AnalyticsContentQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			type
			metadata {
				createdDate
			}
		}
	}
`;

export const AnalyticsDialogQuery = gql`
	query AnalyticsDialogGraphQuery(
		$contentId: ID!
		$graphStartTime: String!
		$graphGranularity: AnalyticsTimeseriesGranularity!
		$countStartTime: String!
		$timezone: String!
	) {
		TimeseriesCount: pageAnalyticsTimeseriesCount(
			pageId: $contentId
			startTime: $graphStartTime
			eventName: [page_viewed]
			granularity: $graphGranularity
			timezone: $timezone
		) {
			nodes {
				date
				count
			}
		}
		UserCount: pageAnalyticsCount(
			pageId: $contentId
			startTime: $countStartTime
			eventName: [page_viewed]
			uniqueBy: USER
		) {
			count
		}
		AllCount: pageAnalyticsCount(
			pageId: $contentId
			startTime: $countStartTime
			eventName: [page_viewed]
			uniqueBy: ALL
		) {
			count
		}
	}
`;

export const AnalyticsPopularQuery = gql`
	query AnalyticsPopularQuery {
		popularFeed(first: 10) {
			nodes {
				content {
					id
				}
			}
		}
	}
`;

export const AnalyticsViewersQuery = gql`
	query AnalyticsViewersQuery($contentId: ID!, $limit: Int, $accountIds: [String]) {
		singleContent(id: $contentId) {
			id
			contentAnalyticsViewsByUser(limit: $limit, accountIds: $accountIds) {
				pageViews {
					userProfile {
						id
						displayName
						photos {
							value
							isPrimary
						}
						isActive
						confluence {
							permissionType
						}
					}
					views
					lastViewedAt
					lastVersionViewed
					lastVersionViewedUrl
				}
			}
		}
	}
`;

export const AnalyticsNonViewersQuery = gql`
	query AnalyticsNonViewersQuery($accountIds: [String]) {
		confluenceUsers(accountIds: $accountIds) {
			nodes {
				displayName
				profilePicture {
					path
				}
				permissionType
				... on KnownUser {
					accountId
				}
			}
		}
	}
`;
